import "./style.css";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import httpServ from "../../services/http.service";
import localStorageServ from "../../services/locaStorage.service";
import { setUserInfor } from "../../redux/reducer/authReducer";
import { Modal } from "antd";
import img_heart from "../../assets/img/heart_heath_2.png";

export default function SpinnerComponent() {

  const { isLoading } = useSelector(state => state.spinner)
  let [isShow, setIsShow] = useState(false);


  let dispatch = useDispatch();
  let timeStart = localStorage.getItem("ENILNOYEK_T");
  let dem = new Date() - new Date(timeStart);
  
  if (timeStart) {
    let demStore = localStorage.getItem("ENILNOYEK_E");


    if ((Number(demStore) + Number(dem)) >= 3600000) {
      localStorage.setItem("ENILNOYEK_T", new Date())
      localStorage.setItem("ENILNOYEK_E", 0)

      let getInfo = localStorage.getItem("USER_INFOR")
        ? JSON.parse(localStorage.getItem("USER_INFOR"))
        : { id: 0 };

      httpServ.tangHp(getInfo).then(res => {
        httpServ.getInforUser(getInfo?.id)
          .then((res) => {
            localStorageServ.userInfor.set(res.data.content);
            let userInfor = res.data.content;
            dispatch(setUserInfor(userInfor));
            setIsShow(true)
          })
      });
    }


  }


  return isLoading ? (
    <div className="page-overlay-wrapper">
      <div class="mask">
        <div class="loader"></div>
      </div>
    </div>
  ) : (
    <Fragment>

      <Modal
        title="Thông tin vật phẩm"
        className="rounded-xl p-0 overflow-hidden felx flex-col items-center dialogItem"
        visible={isShow}
        onCancel={() => {
          setIsShow(false)
        }}
        footer={null}
      >
        <div className="w-full flex  items-center justify-center  space-x-10 px-5">
          <div className=" flex flex-col justify-center space-x-3 items-end">
            <h2 className="font-medium text-xl text-center py-10">
              Wow, bạn thật là một người cần cù và nỗ lực không ngừng, nhận được
              <span className="text-red-500"> 50 </span>
              <img src={img_heart} className="w-8 inline-block" alt="" />
              điểm tích cực
            </h2>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}


