import React, { useEffect, useState, useReducer } from "react";


import httpServ from "../services/http.service";
import { useDispatch, useSelector } from "react-redux";
import Item_ListDiem_LoTrinh from "../components/Item_ListDiem_LoTrinh.js/Item_ListDiem_LoTrinh";
// import PaginationBar from "../components/Pagination/PaginationBar";
import { setDanhSachLoTrinh } from "../redux/reducer/diemAndChungNhanReducer";
import lotrinhLottie1 from "../assets/lottie_json/chungNhanLottie.json";
import Lottie from "lottie-react";
import { getLoTrinhDaDangKiAciton } from "../redux/reducer/loTrinhReducer";
import axios from "axios";
import { Modal, Tabs } from 'antd';
import { debounce } from "lodash";
import ReactPlayer from "react-player";
import { Player, ControlBar, PlaybackRateMenuButton } from 'video-react'
import HLSSource from "../components/HLSSource";


const API_URL = "https://apicrm.cybersoft.edu.vn";

// Initial state for the reducer
const initialState = {
  danhSachKhoaHocFree: [],
  source: "",
  titleVideo: "",
  khoaHocSelect: 0,
  danhSachChuongHocFree: []
};

// Reducer function to update state based on actions
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_DANH_SACH_KHOA_HOC_FREE':
      return {
        ...state,
        danhSachKhoaHocFree: payload,
        khoaHocSelect: payload.at(0).id
      };

    case 'SET_KHOA_HOC_SELECT':
      // console.log(payload)
      return {
        ...state,
        khoaHocSelect: payload,
      };

    case 'SET_DANH_SACH_CHUONG_HOC_FREE':
      return {
        ...state,
        danhSachChuongHocFree: payload,
      };
    case 'SET_SOURCE_VIDEO':
      return {
        ...state,
        source: payload,
      };
    default:
      return state;
  }
};


export default function ChiaSe() {

  const [state, dispatchReducer] = useReducer(reducer, initialState);
  const { userInfor } = useSelector((state) => state.authUser);
  const [visible, setVisible] = useState(false)

  // console.log(state)



  useEffect(() => {
    let isMount = true;

    if (isMount) {
      axios.get(`${API_URL}/api/khoahoc/danh-sach-khoa-hoc-free`)
        .then((res) => {
          dispatchReducer({
            type: "SET_DANH_SACH_KHOA_HOC_FREE",
            payload: res.data.content
          })
        })
    }

    return () => {
      isMount = false;
    }
  }, [])



  useEffect(() => {
    let isMount = true;

    if (isMount && state.khoaHocSelect !== 0) {
      axios.get(`${API_URL}/api/khoahoc/free/${state.khoaHocSelect}`)
        .then((res) => {
          dispatchReducer({
            type: "SET_DANH_SACH_CHUONG_HOC_FREE",
            payload: res.data.content
          })
        })
    }

    return () => {
      isMount = false;
    }
  }, [state.khoaHocSelect])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoTrinhDaDangKiAciton(userInfor?.id));

    httpServ
      .getAllDiemBaiTapUser(userInfor.id)
      .then((res) => {
        dispatch(setDanhSachLoTrinh(res.data.content));
        // console.log("res chung nhan page ", res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  return (
    <div className="w-full h-full flex flex-grow  p-3  flex-col ">
      <div className="w-full   card_theme flex-grow   space-y-7 p-3 ">

        <div className="w-full flex items-center card_theme space-x-10">

          <div className="w-full">
            <TabKhoaHocFree danhSachKhoaHoc={state.danhSachKhoaHocFree}
              danhSachChuongHoc={state.danhSachChuongHocFree.thongTinChuongHoc}
              khoaHocSelect={state.khoaHocSelect}
              dispatchReducer={dispatchReducer}
              setVisible={setVisible}
            />
          </div>

        </div>

        <Modal
          title={"Chia sẻ miễn phí !"}
          visible={visible}
          onCancel={() => {
            setVisible(false)

          }}
          footer={null}
          centered
          width={1000}
        >


          <div className="flex-grow h-full flex items-center water-mark-wrapper">
            <Player

              playsInline
              fluid={false}
              width={"100%"}
              height={"100%"}
              autoPlay={false}
            >
              <HLSSource isVideoChild src={state.source} />
              <ControlBar>
                <PlaybackRateMenuButton
                  rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                  order={10}
                />
              </ControlBar>
            </Player>


          </div>

        </Modal>


      </div>
    </div>
  );
}

function TabKhoaHocFree({ danhSachKhoaHoc, danhSachChuongHoc, dispatchReducer, khoaHocSelect, setVisible }) {

  const [danhSachVideo, setDanhSachVideo] = useState(() => [])

  
  return <div className="h-100 my-auot">
    <Tabs  onChange={(key) => {

      dispatchReducer({
        type: "SET_KHOA_HOC_SELECT",
        payload: key
      })
    }}>
      {
        danhSachKhoaHoc.map((item, index) => {

          return <Tabs.TabPane tab={item.tenKhoaHoc} key={item.id}>
            {danhSachChuongHoc?.length > 0 ? <ListButtonChanel setVisible={setVisible} dsVideo={danhSachChuongHoc.at(0)?.thongTinBaiHoc} dispatchReducer={dispatchReducer} /> : null}
          </Tabs.TabPane>
        })
      }
      <Tabs.TabPane tab={`Agile/Scrum Skill dành cho dev pass phỏng vấn`} key={100} >
        <a href="https://www.youtube.com/watch?v=lMUaLfzOb5I&list=PLwO_PVO47V1ZlPs3CLfSEkII8JdIbU8fn&index=1" target="_blank">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 m-2 rounded" > <i className="fa fa-play"></i> Xem Video </button>

        </a>
      </Tabs.TabPane>
    </Tabs>

  </div>

}

function ListButtonChanel({ dsVideo, dispatchReducer, setVisible }) {



  const handleGetVideoTrongNuoc = debounce((item) => {

    axios.get(`${API_URL}/api/file/ftp-video/${item?.noiDung}`)
      .then((res) => {
        dispatchReducer({
          type: "SET_SOURCE_VIDEO",
          payload: res.data
        })
      })
      .catch((err) => {
        console.log(err)
      })


  }, 500)

  const handleGetVideoNuocNgoai = debounce((item) => {
    axios.get(`${API_URL}/api/file/ftp-video-digital/${item?.noiDung}`)
      .then((res) => {
        dispatchReducer({
          type: "SET_SOURCE_VIDEO",
          payload: res.data
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }, 500)

  return (
    dsVideo?.map((item, index) => (
      <div
        className=" w-full rounded card_theme p-3 space-x-5 flex items-center justify-between"
        key={index}
      >
        {/* <p className="text-base m-0">{item.tenBaiHoc} - <span className="text-red-500">{item.soLuotXem} lượt xem</span></p> */}
        <p className="text-base m-0">{item.tenBaiHoc}</p>
        <div>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 m-2 rounded"
            onClick={() => {
              handleGetVideoTrongNuoc(item)
              setVisible(true)
            }}
          //   disabled={isDisableButton}
          >
            <i class="fa fa-play-circle" aria-hidden="true"></i>
            <span> Server 1</span>
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-2 rounded"
            onClick={() => {
              handleGetVideoNuocNgoai(item)
              setVisible(true)

            }}
          //   disabled={isDisableButton}
          >
            <i class="fa fa-play-circle" aria-hidden="true"></i>
            <span> Server 2</span>
          </button>
        </div>
      </div>
    ))
  );
}