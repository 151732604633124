export const LoTrinhChuaDangKi_Fake_DATA = [
  {
    id: 2,
    tenLoTrinh: "Combo Lập trình Front End Foundation",
    iconNgonNgu:
      '["fa-html5","fa-css3-alt","fa-js","fa-github-square","fa-sass"]',
    danhSachKhoaHoc: [
      {
        id: 14,
        tenKhoaHoc: "Khóa 1: Định hướng nghề Front End, UI - UX nền tảng",
        moTa: "<h2><strong>Định hướng, kinh nghiệm &amp; Lộ trình Front End</strong></h2><ul><li>Định hướng lộ trình</li><li>Các kinh nghiệm khi đi làm</li><li>Cách tìm hiểu và giải quyết vấn đề</li></ul><h2><strong>Kiến thức về UI &amp; UX</strong></h2><ul><li>Phân biệt UI &amp; UX</li><li>UX trong lập trình Front End</li><li>Cách xây dựng sản phẩm có Front End tốt</li><li>Giải quyết các vấn đề liên quan UX trong Front End</li><li>Material Design cho Front End</li></ul><h2><strong>Xây dựng Landing page tối ưu</strong></h2><ul><li>Nền tảng cốt lõi trong xây dựng Landing page</li><li>Các yếu tố giúp thành công trong Landing page</li><li>Các thành phần chính của landing page</li><li>Hướng dẫn xây dựng Landing page</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-36-05-vue_for_designers_800x600_cover_new.png",
        tongSoPhut: 153,
        tongBaiHoc: 12,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 15,
        tenKhoaHoc:
          "Khóa 2: HTML, CSS, HTML5, CSS3, Layout, Flexbox qua 6 dự án nền tảng đến chuyên sâu",
        moTa: "<h2><strong>Dự án 1: Build web KFC sử dụng kiến thức nền tảng</strong></h2><ul><li>Tổng quan web &amp; internet</li><li>Công cụ thực hiện</li><li>Luyện tập các thẻ HTML qua dự án Web Coffee</li><li>Luyện tập các thẻ HTML qua dự án Web KFC - Bài nộp chấm điểm - Nhận xét review bởi mentor &amp; giảng viên</li></ul><h2><strong>Dự án 2: Code CSS qua Dự án Thiết kế nội ngoại thất</strong></h2><ul><li>Nền tảng CSS</li><li>Phân loại CSS</li><li>CSS Selector</li><li>Phân tích bố cục website</li><li>CSS Text&amp; CSS Font &amp; CSS Padding &amp; margin&amp; CSS Display &amp; List Style &amp; CSS float &amp; Border &amp; CSS Position &amp; CSS Table &amp; Chèn &amp; Sử dụng thư viện font</li><li>Dự án Nộp chấm điểm : CyberTravel &amp; CyberMarket</li></ul><h2><strong>Dự án 3 : Luyện tập HTML &amp; CSS qua dự án VUS</strong></h2><ul><li>Chia bố cục</li><li>Vận dựng tất cả thẻ HTML đã học</li><li>Vận dụng CSS đã học</li></ul><h2><strong>Dự án 4: CyberLayout - Code HTML5 &amp; Form &amp; Flexbox &amp; Grid &amp; CSS3</strong></h2><ul><li>Nền tảng HTML5</li><li>Phân tích &amp; tạo cấu folder</li><li>Phân chia bố cục trang</li><li>Các thẻ ngữ nghĩa HTML5</li><li>Flexbox</li><li>CSS Grid layout</li><li>Xử lý video</li><li>Xử lý iframe</li><li>Toàn bộ về Form</li><li>Graphic Elements svg canvas</li><li>CSS3 layout</li><li>CSS transform</li><li>CSS3 animation</li><li>Code hiệu ứng toàn bộ trang sử dụng các plugin thực tế</li><li>Dự án Nộp chấm điểm : CyberHoliday</li></ul>",
        hinhAnh: "/images/24-09-2021-04-35-57-swiftui_ios_14.png",
        tongSoPhut: 1538,
        tongBaiHoc: 43,
        tongBaiTap: 3,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 25,
        tenKhoaHoc: "Khóa: Thao tác cơ bản cắt layout với Photoshop",
        moTa: "",
        hinhAnh: "/images/16-06-2020-02-05-56-khoa_3_thao_tac_photoshop.jpg",
        tongSoPhut: 35,
        tongBaiHoc: 15,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 29,
        tenKhoaHoc: "Khóa 3: Bootstrap và Responsive design trong thực tế",
        moTa: "<p>Khóa update mới</p>",
        hinhAnh: "/images/24-09-2021-04-33-45-flutter_part_2_800x600_cover.png",
        tongSoPhut: 1401,
        tongBaiHoc: 58,
        tongBaiTap: 1,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 17,
        tenKhoaHoc: "Khóa 4: Nền tảng & chuyên sâu SASS/SCSS thực tế qua dự án",
        moTa: "<h2><strong>Nền tảng về SASS/SCSS</strong></h2><ul><li>Lý do sử dụng SASS/SCSS trong thực tế</li><li>Kinh nghiệm khi làm việc với SASS</li><li>Các kiến thức cốt lõi về SASS</li><li>Cài đặt công cụ hỗ trợ SASS</li><li>Chuẩn BEM trong việc tạo biến, CSS, File</li></ul><h2><strong>Phân tích &amp; xây dựng dự án Udemy light &amp; dark theme sử dụng SASS/SCSS</strong></h2><ul><li>Cách tổ chức SASS/SCSS cho dự án thực tế</li><li>Biến</li><li>Gom nhóm</li><li>Mixin</li><li>Extend</li><li>Responsive Web với SASS</li><li>Triển khai trọn vẹn dự án với 2 theme dark và light</li><li>Dự án Nộp chấm điểm : Massive hoặc Fiverr</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-35-45-learn_arkit_800x600_cover_new.png",
        tongSoPhut: 1253,
        tongBaiHoc: 43,
        tongBaiTap: 1,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 18,
        tenKhoaHoc:
          "Khóa 5: Toàn tập Javascript, JS DOM & JS OOP nền tảng và chuyên sâu",
        moTa: "<h2><strong>Kiến thức nền tảng lập trình Javascript</strong></h2><ul><li>Tổng quan về Javascript</li><li>Cách sử dụng Javascript</li><li>Cách thức hoạt động của Javascript</li></ul><h2><strong>Luyện JS qua dự án tính tiền TIPS và GRAB</strong></h2><ul><li>Phân tích nghiệp vụ</li><li>Tổ chức dự án, cây thư mục</li><li>Các kiến thức nền tảng: Biến, kiểu dữ liệu, hằng, hàm, toán tử</li><li>Toàn bộ JS DOM (Document Object Model) trong dự án</li><li>Triển khai dự án</li></ul><h2><strong>Luyện Javascript nâng cao qua dự án Quản lý hệ thống sinh viên &amp; học tập</strong></h2><ul><li>Nền tảng và chuyên sâu Javascript OOP</li><li>Nền tảng và chuyên sâu Javascript Prototype</li><li>Phân tích nghiệp vụ</li><li>Tổ chức dự án</li><li>Phân tích sơ đồ lớp &amp; tổ chức các lớp sử dụng JS Prototype</li><li>Code theo nghiệp vụ</li><li>Áp dụng thuật toán cờ hiệu, thuật toán tìm kiếm, thuật toán sắp xếp</li><li>Tạo bộ lọc filter, sort bằng code thuần JS cho table</li></ul><h2><strong>Luyện JS qua một số dự án khác</strong></h2><ul><li>Phân tích nghiệp vụ</li><li>Tổ chức dự án, cây thư mục</li><li>Triển khai dự án</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-35-35-sketch_plugin_800x600_cover_new.png",
        tongSoPhut: 793,
        tongBaiHoc: 54,
        tongBaiTap: 1,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 19,
        tenKhoaHoc: "Khóa 6: Javascript ES6 nền tảng và chuyên sâu",
        moTa: "<h2><strong>Kiến thức Javascript chuẩn ES6 trở lên</strong></h2><ul><li>Nền tảng ECMAScript ES6</li><li>Biến trong ES6</li><li>Arrow Function</li><li>Default Parameter Values</li><li>Rest Parameter</li><li>Spread Operator</li><li>Destructuring</li><li>Template String</li><li>Object Literal</li><li>for of vs for in</li></ul><h2><strong>Thực hiện dự án với ES6 hướng đối tượng OOP</strong></h2><ul><li>OOP nền tảng chuyên sâu ES6</li><li>Tìm hiểu Proxies</li><li>ES6 import và export</li></ul><h2><strong>Thực hiện dự án Thử Kiếng online với ES6 chuyên sâu</strong></h2><ul><li>Phân tích dự án</li><li>Tổ chức dự án &amp; phân tích sơ đồ lớp đối tượng</li><li>Phân tích giải thuật cho các nghiệp vụ</li><li>Triển khai dự án</li></ul><h2><strong>Thực hiện dự án Todo List với ES6 OOP chuyên sâu</strong></h2><ul><li>Phân tích dự án</li><li>Tổ chức dự án &amp; phân tích sơ đồ lớp đối tượng</li><li>Phân tích giải thuật cho các nghiệp vụ</li><li>Triển khai dự án</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-35-28-advanced_react_hooks_800x600_cover.png",
        tongSoPhut: 517,
        tongBaiHoc: 20,
        tongBaiTap: 0,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 20,
        tenKhoaHoc:
          "Khóa 7: Javascript Ajax Axios ES với API nền tảng & chuyên sâu",
        moTa: "<h2><strong>Xây dựng hệ thống quản lý trắc nghiệm dùng ES6 Ajax</strong></h2><ul><li>Phân tích dự án</li><li>Tổ chức dự án &amp; phân tích sơ đồ lớp đối tượng</li><li>Phân tích giải thuật cho các nghiệp vụ</li><li>Nền tảng về Ajax Axios</li><li>Tìm hiểu Promise</li><li>Async &amp; Await Flow Control</li><li>Sets and WeakSets</li><li>Map and Weak Map</li><li>Một số hàm xử lý mảng (Array) trong ES6</li><li>Triển khai dự án</li></ul><h2><strong>Xây dựng dự án Quản lý sản phẩm dùng ES6, Ajax, call API</strong></h2><ul><li>Phân tích dự án</li><li>Tổ chức dự án &amp; phân tích sơ đồ lớp đối tượng</li><li>Phân tích giải thuật cho các nghiệp vụ</li><li>Ajax Axios call API</li><li>Triển khai dự án</li></ul>",
        hinhAnh: "/images/24-09-2021-04-35-19-principle_800x600_cover_new.png",
        tongSoPhut: 290,
        tongBaiHoc: 27,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 21,
        tenKhoaHoc: "Khóa 8: JQuery nền tảng",
        moTa: "<h2><strong>Nền tảng Jquery</strong></h2><ul><li>Giới thiệu &amp; cách sử dụng</li><li>JQuery CSS</li><li>Jquery DOM</li><li>Jquery Event</li></ul><h2><strong>Xây dựng Dự án Thử đồ trực tuyến với Jquery</strong></h2><ul><li>Phân tích dự án</li><li>Tổ chức dự án &amp; phân tích sơ đồ lớp đối tượng</li><li>Phân tích giải thuật cho các nghiệp vụ</li><li>Triển khai dự án</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-35-07-swiftui_combine_800x600_cover.png",
        tongSoPhut: 165,
        tongBaiHoc: 9,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 22,
        tenKhoaHoc: "Khóa 9: Git, công cụ quản lý nhóm, mô hình Scrum/Agile",
        moTa: "<h2><strong>Git, công cụ quản lý nhóm</strong></h2><ul><li>Tìm hiểu GIT và lý do sử dụng</li><li>Cài đặt GIT</li><li>Các thao tác trên GIT</li><li>Thực hành với GIT</li></ul><h2><strong>Qui trình phát triển phần mềm Agile/Scrum</strong></h2><ul><li>\bTìm hiểu qui trình Scrum/Agile</li><li>Làm việc với Scrum/Agile trong thực tế</li><li>Thực hành Product Backlog</li><li>Thực hành Sprint Backlog</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-34-57-framer_playground_800x600_cover_new.png",
        tongSoPhut: 51,
        tongBaiHoc: 8,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 23,
        tenKhoaHoc:
          "Khóa 10: Tổng hợp các kiến thức qua dự án Honda & Elearning Course",
        moTa: "<h2><strong>Thực hiện trọn dự án Honda</strong></h2><ul><li>Phân tích dự án</li><li>Tổ chức dự án</li><li>Cấu trúc thư mục</li><li>Phân tích layout</li><li>Định nghĩa SASS/SCSS</li><li>Xây dựng toàn bộ layout</li><li>Triển khải animation</li></ul><h2><strong>Thực hiện dự án Elearning course</strong></h2><ul><li>Phân tích dự án</li><li>Tổ chức dự án</li><li>Cấu trúc thư mục</li><li>Phân tích layout</li><li>Định nghĩa SASS/SCSS</li><li>Xây dựng toàn bộ layout</li><li>Triển khải animation</li></ul>",
        hinhAnh: "/images/24-09-2021-04-34-49-webflow_800x600_cover_new.png",
        tongSoPhut: 724,
        tongBaiHoc: 55,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
    ],
    tongBaiHoc: 344,
    tongKhoa: 11,
    tongPhut: 6920,
    tongBaiTapNop: 7,
    tongTracNghiem: 5,
    tongHocVien: 314,
    thoiHan: 6,
    dsAvatar: [
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/1879578238882695/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/3011637355716944/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/5142377922445989/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/1732047186977182/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/3538060969630830/picture?type=large',
      'https://graph.facebook.com/1549019738765517/picture?type=large',
      'https://graph.facebook.com/3015569595377236/picture?type=large',
      'https://graph.facebook.com/2691594044480350/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/1939309819583847/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/419363299372489/picture?type=large'
    ],
    soPhutHoanThanh: 0,
    baiTapNopHoanThanh: 0,
    tracNghiemHoanThanh: 0,
    ngayBatDau: "",
    ngayKetThuc: "",
    daHetHan: true,
    daDangKy: false,
    loaiLoTrinh: [6],
  },
  {
    id: 10,
    tenLoTrinh: "Combo Lập trình Front End Master ReactJS",
    iconNgonNgu:
      '["fa-html5","fa-css3-alt","fa-js","fa-github-square","fa-sass","fa-react"]',
    danhSachKhoaHoc: [
      {
        id: 7,
        tenKhoaHoc: "Khóa 1: React nền tảng qua demo dự án",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-37-14-learn_sketch_800x600_cover_new.png",
        tongSoPhut: 474,
        tongBaiHoc: 24,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 24,
        tenKhoaHoc: "Khóa 2: ReactJS chuyên sâu trên nhiều component",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-34-39-swiftui_part_1_800x600_cover_new.png",
        tongSoPhut: 809,
        tongBaiHoc: 36,
        tongBaiTap: 1,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 26,
        tenKhoaHoc:
          "Khóa 3: React chuyên sâu với life cycle - React function hooks và các ứng dụng.",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-34-22-swift_advanced800x600_cover_new.png",
        tongSoPhut: 1110,
        tongBaiHoc: 55,
        tongBaiTap: 2,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 27,
        tenKhoaHoc: "Khóa 4 React Router & API",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-34-09-react_for_designers800x600_cover_new.png",
        tongSoPhut: 1962,
        tongBaiHoc: 75,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 28,
        tenKhoaHoc:
          "KHOÁ 5: BUILD TOÀN BỘ FRONT END DỰ ÁN ĐẶT VÉ PHIM ONLINE TRONG KHOÁ 5",
        moTa: "",
        hinhAnh: "/images/24-09-2021-04-33-58-principle_800x600_cover_new.png",
        tongSoPhut: 1565,
        tongBaiHoc: 73,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
    ],
    tongBaiHoc: 263,
    tongKhoa: 5,
    tongPhut: 5920,
    tongBaiTapNop: 6,
    tongTracNghiem: 1,
    tongHocVien: 133,
    thoiHan: 5,
    dsAvatar: [
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/1879578238882695/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/3011637355716944/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/5142377922445989/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/1732047186977182/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/3538060969630830/picture?type=large',
      'https://graph.facebook.com/1549019738765517/picture?type=large',
      'https://graph.facebook.com/3015569595377236/picture?type=large',
      'https://graph.facebook.com/2691594044480350/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/1939309819583847/picture?type=large',
      'https://i.kinja-img.com/gawker-media/image/upload/c_scale,f_auto,fl_progressive,pg_1,q_80,w_800/ijsi5fzb1nbkbhxa2gc1.png',
      'https://graph.facebook.com/419363299372489/picture?type=large'
    ],
    soPhutHoanThanh: 0,
    baiTapNopHoanThanh: 0,
    tracNghiemHoanThanh: 0,
    ngayBatDau: "",
    ngayKetThuc: "",
    daHetHan: true,
    daDangKy: false,
    loaiLoTrinh: [6],
  },
];
