export const LoTrinhCuaBan_Fake_Data = [
  {
    id: 1,
    tenLoTrinh: "Combo Tư duy lập trình, thuật toán, hướng đối tượng",
    iconNgonNgu: '["fa-java"]',
    danhSachKhoaHoc: [
      {
        id: 9,
        tenKhoaHoc: "Khóa 1: Tư duy lập trình & nền tảng",
        moTa: "<h2><strong>Kiến thức nền &amp; Chuẩn hóa các sai lầm khi luyện code</strong></h2><ul><li>Loại bỏ các thói quen xấu khi lập trình</li><li>Tổ chức mã nguồn</li><li>Qui tắc đặt biến, hàm</li><li>Tài nguyên, bộ nhớ, tối ưu code</li><li>Phân tích bài toán lập trình bắt đầu từ đâu</li></ul><h2><strong>Các kiến thức cốt lõi của bất kì ngôn ngữ lập trình</strong></h2><ul><li>Kiểu dữ liệu, hằng, biến trong chương trình</li><li>Kích thước lưu trữ</li><li>Thư viện hàm</li><li>Định dạng nhập xuất</li><li>Cấu trúc rẽ nhánh/ điều kiện</li><li>Tổng hợp luyện bài tập rẽ nhánh qua các ví dụ thực tế</li><li>Case study cấu trúc rẽ nhánh lớn</li><li>Mô hình 3 khối CyberSoft</li><li>Cấu trúc lặp</li><li>Tổng hợp bài tập luyện tập căn bản nâng cao</li><li>Case study thực tế</li></ul><h2><strong>Lập trình đơn thể &amp; tổ chức mã nguồn</strong></h2><ul><li>Lý do lập trình đơn thể</li><li>Tham số của hàm</li><li>Nâng cao về hàm</li><li>Refactor code sử dụng Hàm</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-37-48-swiftui_combine_800x600_cover.png",
        tongSoPhut: 778,
        tongBaiHoc: 56,
        tongBaiTap: 3,
        tongTracNghiem: 2,
        soBaiDaHoanThanh: 10,
      },
      {
        id: 10,
        tenKhoaHoc: "Khóa 2: Lập trình xử lý Mảng căn bản chuyên sâu",
        moTa: "<h2><strong>Mảng 1 chiều</strong></h2><ul><li>Các ví dụ sử dụng mảng trong thực tế</li><li>Nền tảng về cấu trúc dữ liệu mảng</li><li>Mảng tĩnh và mảng động</li><li>Các kỹ thuật duyệt mảng</li><li>Thêm phần tử vào mảng</li><li>Xóa phần tử khỏi mảng</li><li>Chèn phần tử vào mảng</li><li>Xử lý các bài tập mảng</li><li>Độ phức tạp (Big-O) khi xử lý các thao tác trên mảng</li><li>Xử lý các bài tập phỏng vấn chuyên sâu với mảng</li></ul><h2><strong>Mảng 2 chiều</strong></h2><ul><li>Các ví dụ sử dụng mảng 2 chiều trong thực tế</li><li>Các kỹ thuật duyệt mảng 2 chiều</li><li>Thao tác trên mảng 2 chiều</li><li>Luyện tập trên mảng 2 chiều</li><li>Dự án thực tế với mảng 2 chiều</li></ul>",
        hinhAnh: "/images/24-09-2021-04-36-51-webflow_800x600_cover_new.png",
        tongSoPhut: 432,
        tongBaiHoc: 33,
        tongBaiTap: 2,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 1,
      },
      {
        id: 12,
        tenKhoaHoc: "Khóa 3: Thuật toán nền tảng qua các dự án",
        moTa: "<h2><strong>Nền tảng thuật toán</strong></h2><ul><li>Big-O, đánh giá độ phức tạp thuật toán</li><li>Kỹ thuật cờ hiệu, tổng, tích lũy, đếm</li><li>Kỹ thuật lính canh</li><li>Luyện tập với dự án thực tế</li></ul><h2><strong>Thuật toán tìm kiếm</strong></h2><ul><li>Ứng dụng của tìm kiếm trong thực tế</li><li>Giải thuật tìm kiếm tuyến tinh</li><li>Luyện tập tìm kiếm tuyến tính qua ví dụ và dự án</li><li>Giải thuật tìm kiếm nhị phân</li><li>Luyện tập tìm kiếm nhị phân qua bài tập và dự án</li></ul><h2><strong>Thuật toán sắp xếp</strong></h2><ul><li>Ứng dụng bài toán sắp xếp trong thực tế</li><li>Đổi chỗ trực tiếp – Interchange Sort - Ý tưởng &amp; Cài đặt</li><li>Nổi bọt – Bubble Sort - Ý tưởng &amp; Cài đặt</li><li>Chọn trực tiếp – Selection Sort - Ý tưởng &amp; Cài đặt</li><li>Chèn trực tiếp – Insertion Sort- Ý tưởng &amp; Cài đặt</li><li>Quick Sort- Ý tưởng &amp; Cài đặt</li></ul><h2><strong>Tổng hợp dự án</strong></h2><ul><li>Luyện tập bài tập với Tìm kiếm và Sắp xếp</li><li>Xây dựng dự án với Tìm kiếm &amp; Sắp xếp thực tế</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-36-32-swiftui_concurrency_800x600_cover.png",
        tongSoPhut: 325,
        tongBaiHoc: 36,
        tongBaiTap: 3,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 6,
      },
      {
        id: 11,
        tenKhoaHoc: "Khoá 4: Lập trình hướng đối tượng nền tảng",
        moTa: "<h2><strong>Các kiến thức cốt lõi trong Lập trình Hướng Đối Tượng</strong></h2><ul><li>Yêu cầu cần phải sử dụng Lập trình Hướng Đối Tượng</li><li>Tính đóng gói trong lập trình hướng đối tượng</li><li>Tính kế thừa trong lập trình hướng đối tượng</li><li>Tính đa hình/đa xạ trong lập trình hướng đối tượng</li><li>Áp dụng hướng đối tượng vào bài toán thực tế</li><li>Xây dựng sơ đồ lớp &amp; Tổ chức dự án chuẩn Hướng đối tượng</li></ul><h2><strong>Luyện tập &amp; Code dự án thực tế</strong></h2><ul><li>Phân tích dự án</li><li>Xây dựng mô hình hướng đối tượng</li><li>Tổ chức các lớp theo nghiệp vụ</li><li>Coding theo nghiệp vụ dự án</li><li>Sử dụng tất cả kiến thức về hướng đối tượng, thuật toán</li><li>Thực hiện Thêm, Xóa, Sửa, Tìm kiếm</li><li>Xây dựng thêm 5 dự án thực tế từ phân tích đến code full</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-36-41-design_system_figma_800x600_cover_new.png",
        tongSoPhut: 1049,
        tongBaiHoc: 54,
        tongBaiTap: 2,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 12,
      },
      {
        id: 13,
        tenKhoaHoc: "Khóa 5: Lập trình hướng đối tượng chuyên sâu",
        moTa: "<h2><strong>Kiến thức lập trình hướng đối tượng chuyên sâu</strong></h2><ul><li>Lớp trừu tượng - Phương thức trừu tượng</li><li>Interface trong hướng đối tượng</li></ul><h2><strong>Thực hiện dự án chuyên sâu thực tế</strong></h2><ul><li>Xây dựng dự án Quản Lý Nhân Sự</li><li>Xây dựng dự án Quản Lý Task</li><li>Phân tích nghiệp vụ &amp; Triển khai</li><li>Phân tích sơ đồ lớp</li><li>Thực hiện các nghiệp vụ Phân bổ, Thêm, Xóa, Sửa</li><li>Áp dụng giải thuật tìm kiếm, sắp xếp cho dự án</li></ul>",
        hinhAnh:
          "/images/24-09-2021-04-38-03-video_editing_in_screenflow_800x600_cover_new.png",
        tongSoPhut: 214,
        tongBaiHoc: 16,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
    ],
    tongBaiHoc: 195,
    tongKhoa: 5,
    tongPhut: 2798,
    tongBaiTapNop: 11,
    tongTracNghiem: 2,
    tongHocVien: 1023,
    thoiHan: 3,
    dsAvatar: [
      "nul",
      "nul",
      "/images/04-02-2021-04-15-27-PicsArt_02-04-03.54.16_mh1612388235837_1612388267473.jpg",
      "nul",
      "nul",
      "nul",
      "nul",
      "nul",
      "/images/08-10-2020-02-04-52-unnamed.jpg",
      "nul",
      "nul",
      "nul",
      "nul",
    ],
    soPhutHoanThanh: 248,
    baiTapNopHoanThanh: 3,
    tracNghiemHoanThanh: 1,
    ngayBatDau: "4/28/2021 12:54:11 PM",
    ngayKetThuc: "9/6/2021 12:54:11 PM",
    daHetHan: true,
    daDangKy: true,
    loaiLoTrinh: [1],
  },
  {
    id: 17,
    tenLoTrinh: "Combo Backend - NodeJS Foundation. Viết API cho dự án thực tế",
    iconNgonNgu: '["fa-node"]',
    danhSachKhoaHoc: [
      {
        id: 30,
        tenKhoaHoc: "Khóa 1: NodeJS nền tảng qua dự án TodoList",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-33-24-learn_sketch_800x600_cover_new.png",
        tongSoPhut: 156,
        tongBaiHoc: 13,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 7,
      },
      {
        id: 31,
        tenKhoaHoc: "Khóa 2: Các kiến thức NodeJS thông qua dự án Weather",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-33-15-learn_arkit_800x600_cover_new.png",
        tongSoPhut: 110,
        tongBaiHoc: 12,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 4,
      },
      {
        id: 32,
        tenKhoaHoc:
          "Khóa 3: Nền tảng, thao tác về cơ sở dữ liệu MySQL. Thực hiện tổ chức luyện cơ sở dữ liệu qua nhiều dự án",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-32-42-greensock_animation_800x600_cover_new.png",
        tongSoPhut: 187,
        tongBaiHoc: 27,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 33,
        tenKhoaHoc:
          "Khóa 4: Thực tế hóa cơ sở dữ liệu nâng cao với dự án Installgram",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-32-29-framer_playground_800x600_cover_new.png",
        tongSoPhut: 189,
        tongBaiHoc: 21,
        tongBaiTap: 0,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 13,
      },
      {
        id: 34,
        tenKhoaHoc:
          "Khóa 5: ExpressJS chuyên sâu thông qua dự án Student Management",
        moTa: "",
        hinhAnh: "/images/24-09-2021-04-31-37-flutter_part_2_800x600_cover.png",
        tongSoPhut: 172,
        tongBaiHoc: 22,
        tongBaiTap: 1,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 3,
      },
      {
        id: 35,
        tenKhoaHoc: "Khóa 6: NodeJS tương tác với MySQL thông qua Sequelize",
        moTa: "",
        hinhAnh: "/images/24-09-2021-04-31-30-flutter_800x600_cover_new.png",
        tongSoPhut: 139,
        tongBaiHoc: 17,
        tongBaiTap: 1,
        tongTracNghiem: 1,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 36,
        tenKhoaHoc: "Khóa 7: Express & Sequelize thông qua dự án vé xe rẻ",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-30-21-design_system_figma_800x600_cover_new.png",
        tongSoPhut: 608,
        tongBaiHoc: 38,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
      {
        id: 37,
        tenKhoaHoc: "Khóa 8: Các kiến thức Socket IO thông qua dự án CyberChat",
        moTa: "",
        hinhAnh:
          "/images/24-09-2021-04-30-04-advanced_react_hooks_800x600_cover.png",
        tongSoPhut: 288,
        tongBaiHoc: 24,
        tongBaiTap: 0,
        tongTracNghiem: 0,
        soBaiDaHoanThanh: 0,
      },
    ],
    tongBaiHoc: 174,
    tongKhoa: 8,
    tongPhut: 1849,
    tongBaiTapNop: 4,
    tongTracNghiem: 2,
    tongHocVien: 48,
    thoiHan: 4,
    dsAvatar: [
      "nul",
      "nul",
      "nul",
      "/images/17-08-2021-10-07-28-IMG_20210227_024945_287.jpg",
      "nul",
      "nul",
      "nul",
      "nul",
      "nul",
      "nul",
      "nul",
      "nul",
      "https://graph.facebook.com/863295194322412/picture?type=large",
    ],
    soPhutHoanThanh: 1269,
    baiTapNopHoanThanh: 1,
    tracNghiemHoanThanh: 1,
    ngayBatDau: "7/21/2021 9:54:00 AM",
    ngayKetThuc: "7/21/2022 9:54:00 AM",
    daHetHan: false,
    daDangKy: true,
    loaiLoTrinh: [7],
  },
];
 