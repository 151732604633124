export const segments = [
  {
    label: "1300K",
    value: "1300K",
  },
  {
    label: "1500K",
    value: "1500K",
  },
  {
    label: "1400K",
    value: "1400K",
  },
  {
    label: "1200K",
    value: " 1200K",
  },
  {
    label: "1600K",
    value: " 1600K",
  },
];

// 1tr2, 1tr3, 1tr4, 1tr5, 1tr6
export const segColors = [
  "#F9AA1F",
  "#F0CF50",
  "#34A24F",
  "#3DA5E0",
  "#EC3F3F",
  "#F9AA1F",
  "#F0CF50",
  "#34A24F",
  "#3DA5E0",
  "#EC3F3F",
  "#F9AA1F",
  "#F0CF50",
  "#34A24F",
  "#3DA5E0",
  "#EC3F3F",
];
// "#3DA5E0",
//   "#34A24F",
//   "#F9AA1F",
//   "#EC3F3F",
