import React from "react";
import { useMediaQuery } from "react-responsive";
import SignUpDesktop from "./SignUpDesktop/SignUpDesktop";
import "./Sigup.css";

export default function SignUpPage() {
  return (
    <SignUpDesktop />

  );
}
