import React, { useState } from "react";
import { useEffect } from "react";
import httpServ from "../../services/http.service";
import Content_Quizz_TestDauVao from "../../components/Content_Quizz_DauVao/Content_Quizz_TestDauVao";
import FormEmail from "./FormEmail";
var qs = require("qs");
var jwt = require("jsonwebtoken");
export default function TestQuizPage() {
  useEffect(() => {
    // check còn hạn hay không
    try {
      let { token } = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      let result = jwt.verify(token, "JAVA_TEST_QUIZ");
    } catch (error) {
      window.location.href = "https://cyberlearn.vn/";
    }
  }, []);
  const [isShowQuiz, setIsShowQuiz] = useState(false);

  return (
    <div className="h-screen">
      {!isShowQuiz ? (
        <FormEmail
          handleSuccess={() => {
            setIsShowQuiz(true);
          }}
        />
      ) : (
        <Content_Quizz_TestDauVao />
      )}
    </div>
  );
}
