import React from "react";
import { Modal } from "antd";

export default function ModalDieuKhoan({ isShowDieuKhoan, handleCloseModal }) {
  return (
    <Modal
      style={{ top: 20 }}
      title="Quy Định Học Tập"
      className="rounded-xl  w-4/5 felx flex-col items-center h-5/6   "
      // onOk={handleOk}
      visible={isShowDieuKhoan}
      onCancel={() => {
        handleCloseModal();
      }}
      footer={
        <button
          onClick={() => {
            handleCloseModal();
          }}
          className=" cursor-pointer card_theme p-3 font-medium text-base text-blue-theme border-none shadow-design_code"
        >
          Đồng ý
        </button>
      }
    >
      {/* java */}
      <div className="space-y-2 p-3 text-color-content">
        <p className="text-xl font-medium text-center text-gray-900">
          ĐIỀU KHOẢN QUY ĐỊNH HỌC TẬP
        </p>
        <p className="text-lg">
          Dưới đây là những điều khoản được áp dụng cho học viên của CyberLearn.
          Xin hãy đọc kỹ toàn bộ thỏa thuận trước khi tham gia. Một khi bạn đã
          đăng ký tham gia trên CyberLearn chúng tôi sẽ hiểu rằng bạn đã đọc và
          đồng ý toàn bộ điều khoản được đưa ra trong bản thỏa thuận này.
        </p>

        <ul className="list-disc">
          <p className="font-medium  text-gray-900 mt-1.5 mb-1">
            Điều 1: Quy định học tập
          </p>
          <li>Xem video trực tuyến.</li>
          <li>Làm bài tập trực tuyến.</li>
          <li>Mentor và giảng viên hỗ trợ học tập qua nhóm học tập.</li>
          <li>Mentor và giảng viên hỗ trợ học tập qua nhóm chat messenger.</li>
          <li>Mentor hỗ trợ sửa lỗi qua teamview theo khung giờ quy định</li>
          <li>Mentor và Giảng viên sẽ chấm bài tập nếu có.</li>
          <li>
            Cần phải hoàn thành bài tập mới được xem video tiếp theo (Không nhảy
            bài).
          </li>
          <li>
            Để đảm bảo học tốt các nội dung tiếp theo trong từng phần của khoá
            học bạn cần phải làm các bài tập trắc nghiệm. Đây là bài tập bắt
            buộc làm (tự làm), không được đăng group hỏi. Khi đạt 50% số lượng
            câu hỏi bạn sẽ Đạt và sẽ hiển thị đáp án và bạn sẽ được học các nội
            dung tiếp theo.{"{"}" "{"}"}
          </li>
          <li>
            Chỉ hỗ trợ đăng nhập qua facebook. Hỗ trợ duy nhất một tài khoản học
            một nơi. Bạn cần bảo mật tài khoản facebook của bạn. Chúng tôi không
            hỗ trợ chuyển khoá học sang tài khoản khác.
          </li>
          <li>KHÔNG hỗ trợ tải video.</li>
          <li>KHÔNG hỗ trợ tải slide bài giảng.</li>
          <li>
            Hết thời hạn học tập, tất cả tài nguyên và các hỗ trợ học tập của
            bạn sẽ không được truy xuất.
          </li>
          <li>Không sao chép và bán khoá học hoặc nhường cho người khác.</li>
          <li>
            KHÔNG HOÀN TRẢ bất kì khoản phí nào đã thanh toán cho trung tâm với
            bất kì lý do nào sau khi đã làm Form đăng kí !
          </li>
          <li>Không hoàn phí khi đã đăng ký.</li>
          <li>Không hỗ trợ gia hạn từng khoá riêng lẻ trong lộ trình.</li>

          <p className="font-medium  text-gray-900 mt-1.5 mb-1">
            Điều 2: Thông tin tài khoản cá nhân
          </p>
          <li>
            Khi đăng ký tài khoản tại CyberLearn.vn, học viên phải cung cấp đầy
            đủ và chính xác thông tin về Tên, Email, Số điện thoại, số CMND. Đây
            là những thông tin bắt buộc liên quan tới việc hỗ trợ học viên trong
            quá trình sử dụng dịch vụ tại CyberLearn. Khi có những rủi ro, mất
            mát sau này, chúng tôi chỉ tiếp nhận những trường hợp điền đúng và
            đầy đủ những thông tin trên. Những trường hợp điền thiếu thông tin
            hoặc thông tin sai sự thật sẽ không được giải quyết. Những thông tin
            này sẽ được dùng làm căn cứ để hỗ trợ giải quyết.
          </li>
          <p className="font-medium  text-gray-900 mt-1.5 mb-1">
            Điều 3: Việc bảo mật thông tin
          </p>

          <li>
            Bạn có trách nhiệm tự mình bảo quản Tài Khoản, nếu Tài Khoản bị lộ
            ra ngoài dưới bất kỳ hình thức nào, CyberLearn sẽ không chịu trách
            nhiệm về mọi tổn thất phát sinh.{"{"}" "{"}"}
          </li>
          <li>
            Bạn không được download video, không được chia sẻ video, tài liệu,
            mã nguồn, tài nguyên mà CyberLearn cung cấp cho bạn lên Internet với
            bất kỳ hình thức nào. Nếu vi phạm, tài khoản của bạn sẽ bị dừng hoạt
            động và phải chịu trách nhiệm trước pháp luật về hành vi xâm phạm sở
            hữu trí tuệ. CyberLearn có thể gửi thông báo tình hình học tập,
            chương trình khuyến mãi (nếu có), thông báo khóa học mới sắp ra mắt
            để học viên quan tâm có thể đăng ký ngay để được ưu đãi.
          </li>

          <p className="font-medium  text-gray-900 mt-1.5 mb-1">
            Điều 4: Ghi danh khóa học
          </p>
          <li>
            Khoá học đóng học phí theo từng tháng, bạn cần thanh toán đủ và kích
            hoạt khóa học.
          </li>

          <li>
            KHÔNG HOÀN TRẢ bất kì khoản phí nào đã thanh toán cho trung tâm với
            bất kì lý do nào sau khi đã làm Form đăng kí !
          </li>
          <li>
            Để tiếp tục gia hạn khóa học bạn cần thanh toán trước 5 ngày trước
            khi hết tháng.
          </li>

          <p className="font-medium  text-gray-900 mt-1.5 mb-1">
            Điều 5: V/V Tạm dừng tài khoản khóa học
          </p>
          <p>
            Nếu trong quá trình học hệ thống CyberLearn phát hiện ra các hành vi
            tải video chúng tôi sẽ dừng tài khoản học tập và không báo trước,
            chúng tôi sẽ xem xét và xử lý tùy theo mức độ vi phạm
          </p>
          <p className="font-medium  text-gray-900 mt-1.5 mb-1">
            Điều 6: Quy định cấp chứng nhận
          </p>
          <li>Điều kiện để được cấp chứng nhận:</li>

          <li>Bắt buộc hoàn thành các bài tập và trắc nghiệm</li>
          <li>Khi đạt tổng điểm từ 7 trở lên (&gt;=7). </li>
          <li>Tổng điểm gồm:</li>

          <li>Bài tập và trắc nghiệm (40%)</li>
          <li>Dự án cuối khoá (60%)</li>
          <li>Thang điểm 10/10</li>

          <li>
            Hoàn thành dự án cuối khóa (deadline 1,5 tháng), điểm dự án từ 7 trở
            lên (&gt;=7)
          </li>

          <li>Cách thức nhận chứng nhận:</li>

          <li>Nhận chứng nhận online và có mộc của công ty</li>
          <li>
            Các bạn có nhu cầu nhận bản cứng sẽ nhận tại văn phòng cho khu vực
            HCM. Các khu vực khác (trong phạm vi VN), chứng nhận sẽ gửi qua bưu
            điện và free ship
          </li>
        </ul>
      </div>
    </Modal>
  );
}
