import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import httpServ from "../../services/http.service";
import { setUserInfor } from "../../redux/reducer/signUpReducer";

export default function FormDieuKhoan({ handleOpenModal, handleSuccess }) {
  const { Option } = Select;
  let dispatch = useDispatch();
  const onFinish = (values) => {
    // handleSuccess();

    httpServ
      .getKiemTraEmailTestDauVao(values.email)
      .then((res) => {
        if (res.data.content != 0 ) {
          message.error("Bạn đã làm bài test rồi");
        } else {
          handleSuccess();
          dispatch(
            setUserInfor({
              email: values.email,
            })
          );
          // nếu check gmail  thành công thì gọi hiện màn hình test
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div className="w-full flex justify-center flex-col items-center ">
      <Form
        name="basic"
        className=" w-full sm:p-3 md:p-5  lg:w-5/6  "
        labelCol={{ sm: { span: 6 }, xl: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 }, lg: { span: 16 } }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "Email không đúng định dạng",
            },
            {
              required: true,
              message: "Trường này không được để trống",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{ sm: { offset: 0 }, md: { offset: 4 }, span: 16 }}
          name="agreement"
          label=""
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Hãy đọc và đồng ý các điều khoản của CyberLearn",
            },
          ]}
        >
          <Checkbox>
            Tôi đồng ý với các{" "}
            <span
              onClick={() => {
                handleOpenModal();
              }}
              className="text-red-700 underline cursor-pointer"
            >
              ĐIỀU KHOẢN
            </span>{" "}
            của CyberLearn
          </Checkbox>
        </Form.Item>
        <Form.Item
          wrapperCol={{ sm: { offset: 0 }, md: { offset: 4 }, span: 16 }}
        >
          <div className="w-full flex justify-center space-x-3 ">
            <Button className="w-24" type="primary" htmlType="submit">
              Tiếp theo
            </Button>
          </div>
        </Form.Item>
      </Form>

      <div></div>
    </div>
  );
}
