import { createSlice } from "@reduxjs/toolkit";
import localStorageServ from "../../services/locaStorage.service";
var fakeData = {
  signUp: {
    userInfor: {
      email: "testjava@gmail.com",
      hoTen: "test",
      soDT: "4234234234243",
      maLoTrinh: "  [ int , int ]",
      avatar: "  string",
      urls: "  string",
      thongTinMoRong: {
        namSinh: "  string",
        soCmnd: "  string",
        hinhCmnd: [],
        congViecHienTai: "  string",
        noiCongTacHienTai: "Hà Nội",
        nguonGioiThieu: "  string",
        facebookUrl: "  string",
        luongMongMuon: "  int",
        dongYHoTroTimViec: false,
        predictiveIndexRequire: ["string", "string"],
        predictiveIndex: ["string", "string"],
      },
      maGioiThieu: "",
      linkNopBai: "",
    },
    currentStep: 0,
    isShowModal: false,
    listCMND: [],
    loading: false,
    listCauHoi: [],
  },
};
const initialState = {
  userInfor: {
    email: "  string",
    hoTen: "  string",
    soDT: "  string",
    maLoTrinh: "  [ int , int ]",
    avatar: "  string",
    urls: "  string",
    thongTinMoRong: {
      namSinh: "  string",
      soCmnd: "  string",
      hinhCmnd: [],
      congViecHienTai: "  string",
      noiCongTacHienTai: "  string",
      nguonGioiThieu: "  string",
      facebookUrl: "  string",
      luongMongMuon: "  int",
      dongYHoTroTimViec: false,
      predictiveIndexRequire: ["string", "string"],
      predictiveIndex: ["string", "string"],
    },
    maGioiThieu: "",
    linkNopBai: "",
  },
  currentStep: 0,
  isShowModal: false,
  listCMND: [],
  loading: false,
  listCauHoi: [],
  isJavaCourse: false,
  // listCauHoi: fakedata,
};

export const SignUpSlice = createSlice({
  name: "signUpReducer",
  initialState,
  reducers: {
    setUserInfor: (state, action) => {
      state.userInfor = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setImgCMND: (state, action) => {
      state.listCMND = action.payload;
    },
    setLoadingSigup: (state, action) => {
      state.loading = action.payload;
    },
    setListCauHoiDauVao: (state, action) => {
      state.listCauHoi = action.payload;
    },
    setIsJavaCourse: (state, action) => {
      state.isJavaCourse = action.payload;
    },
  },
});
export const {
  setIsJavaCourse,
  setSignUp,
  setCurrentStep,
  setUserInfor,
  setLoadingSigup,
  setListCauHoiDauVao,
} = SignUpSlice.actions;
export default SignUpSlice.reducer;
